function setEqualHeight() {
    $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".arsluna-row .grid-element-content.equal").matchHeight();
    $(".arsluna-row .grid-element-content.equal-level-2").matchHeight();
    $(".grid-menu-item-002 .menu-item-content").matchHeight();
    $(".grid-news-item article").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
    // $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        scroll_limit = 60,
        initial_logo_height = 200,
        logo_scale_faktor = 1,
        logo_height,
        header_height;
    switch (current_breakpoint) {
        case "xlarge":
            scroll_limit = 50;
            initial_logo_height = 160;
            break;
        case "large":
            scroll_limit = 40;
            initial_logo_height = 140;
            break;
        case "medium":
            scroll_limit = 40;
            initial_logo_height = 130;
            break;
        case "small":
            scroll_limit = 40;
            initial_logo_height = 130;
            break;
        case "xsmall":
            scroll_limit = 30;
            initial_logo_height = 100;
            break;
        default:
            scroll_limit = 60;
            initial_logo_height = 200;
    }
    if ($(window).scrollTop() <= scroll_limit) {
        logo_height = initial_logo_height - $(window).scrollTop() * logo_scale_faktor;
        margin_top = -$(window).scrollTop();
        logo_margin_top = -scroll_limit + $(window).scrollTop();
    } else {
        logo_height = initial_logo_height - scroll_limit * logo_scale_faktor;
        margin_top = -scroll_limit;
        logo_margin_top = 0;
    }
    if ($(window).scrollTop() === 0) {
        $("#header-wrapper").css("margin-top", "");
        $("#logo a").css({"height":  "", "width": "", "top": ""});
    } else {
        $("#header-wrapper").css("margin-top", margin_top + "px");
        $("#logo a").css({"height": logo_height + "px", "width": logo_height + "px", "top": logo_margin_top + "px"});
    }
});
